import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../modules/auth/services/auth.service';
import { LocalStorageService } from './local-storage.service';
import { ResizeService } from './resize.service';

import { PageHitDataService } from './page-hit-data.service';
import { SessionStorageService } from './session-storage.service';
import { UniqueIdService } from './unique-id.service';
import { WINDOW } from './window.service';

@Injectable({
  providedIn: 'root'
})
export class PageHitSharingService {

  private pageHitInfo = null;
  private screenSize = null;
  private storageKeyClient = 'CLIENT';
  private storageKeyPartner = 'PARTNER_HIT';
  private storageKeyTab = 'TAB_ID';

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    private localStorageService: LocalStorageService,
    private pageHitDataService: PageHitDataService,
    @Inject(PLATFORM_ID) private platformId: object,
    private resizeService: ResizeService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private uniqueIdService: UniqueIdService,
    @Inject(WINDOW) private window: Window | null
  ) {

    // Get the current screen size
    this.resizeService.onResize.subscribe(size => this.screenSize = size);
  }

  recordPageHit(appPrefix: string = null, overrideURL: string = null) {

    // Check whether the user is customer
    if (this.authService.isAuthenticated() && !this.authService.isAuthorized(['CUSTOMER'])) {
      return;
    }

    // Read the client id
    let clientId = this.localStorageService.getItem(this.storageKeyClient);

    // If the client id doesn't exist yet, check obsolete partner hit record
    if (!clientId) {

      // Read the hit record
      const value = this.localStorageService.getItem(this.storageKeyPartner);

      // Initialize client id based on the stored value
      if (value) {
        const partnerData = JSON.parse(value);
        clientId = partnerData.clientId;

        // Remove the obsolete partner hit record
        this.localStorageService.removeItem(this.storageKeyPartner);
      } else {

        // Generate a new client id
        clientId = this.uniqueIdService.get();
      }

      // Store the client id to the local storage
      this.localStorageService.setItem(this.storageKeyClient, clientId);
    }

    // Set the tab identifier if not already set
    if (!this.sessionStorageService.getItem(this.storageKeyTab)) {

      // Generate a pseudo unique id and set it in SessionStorage
      const tabId = Date.now().toString(36) + Math.random().toString(36).substring(2);
      this.sessionStorageService.setItem(this.storageKeyTab, tabId);
    }

    // Prepare the url
    let url = '';
    if (overrideURL) {
      url = overrideURL;
    } else {

      // If an appPrefix is set, perpend it to the router url
      // TODO - switch to APP_BASE_HREF
      url = appPrefix ? `/${appPrefix}${this.router.url}` : this.router.url;
    }

    // Create the data object for page hit record
    this.pageHitInfo = {
      clientId: clientId,
      createdOn: new Date(),
      partnerUrlId: this.activatedRoute.snapshot.queryParamMap.get('p'),
      referrerUrl: isPlatformBrowser(this.platformId) ? this.document.referrer.substring(0, 2048) : '',
      screenSize: this.screenSize,
      tabId: this.sessionStorageService.getItem(this.storageKeyTab),
      userAgent: this.window ? this.window.navigator.userAgent || this.window.navigator.vendor : '',
      utmCampaign: this.activatedRoute.snapshot.queryParamMap.get('utm_campaign'),
      utmContent: this.activatedRoute.snapshot.queryParamMap.get('utm_content'),
      utmMedium: this.activatedRoute.snapshot.queryParamMap.get('utm_medium'),
      utmSource: this.activatedRoute.snapshot.queryParamMap.get('utm_source'),
      utmTerm: this.activatedRoute.snapshot.queryParamMap.get('utm_term'),
      url: url
    };

    // Save record to the database
    this.pageHitDataService.create(this.pageHitInfo)
      .subscribe(
        (value) => this.pageHitInfo.pageHitId = value.PageHitId,
        (error) => console.error(error)
      );
  }
}
