import { AfterContentChecked, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import { BlogDataService } from '../../../shared/services/blog-data.service';
import { BlogSharingService } from '../../../shared/services/blog-sharing.service';
import { TelemetryService } from '@shared/services/telemetry.service';

// Icons
import { faChevronLeft, faChevronRight, faSpinner } from '@fortawesome/free-solid-svg-icons';

// Animations
import { slideInOut } from '@shared/animations/slide-in-out';

@Component({
  animations: [slideInOut],
  selector: 'home-blog-posts-carousel',
  templateUrl: './blog-posts-carousel.component.html',
  styleUrls: ['./blog-posts-carousel.component.scss'],
  standalone: false
})
export class BlogPostsCarouselComponent implements AfterContentChecked, OnInit {

  @Input() mode: string; // 'BLOG' or 'SUPERSTAR'
  @ViewChild('carousel', {static: true}) carouselElement: ElementRef;

  baseURL: string = null;
  errorReading = '';
  posts: any[] = null;
  showLeftArrow: boolean = null;
  showRightArrow: boolean = null;
  title: string = null;

  // Icons
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faSpinner = faSpinner;

  readonly CAROUSEL_SCROLL_WIDTH = 570;

  @HostListener('window:resize', ['$event']) onResize($event) {
    this.updateArrows();
  }

  constructor(
    private blogDataService: BlogDataService,
    private blogSharingService: BlogSharingService,
    private telemetryService: TelemetryService
  ) { }

  ngAfterContentChecked() {
    this.updateArrows();
  }

  ngOnInit() {

    // Set the base URL (e.g. /blog or /our-students)
    this.baseURL = this.mode === 'BLOG' ? '/blog' : '/our-students';
    this.title = this.mode === 'BLOG' ? 'BLOG POSTS' : 'OUR STUDENTS';

    const labels = this.mode === 'BLOG' ? 'Homepage' : 'Homepage,SuperStar';
    this.read(labels);
  }

  scrollCarousel(direction: string) {
    this.carouselElement.nativeElement.scrollTo({
      left: (this.carouselElement.nativeElement.scrollLeft + (direction === 'RIGHT' ? this.CAROUSEL_SCROLL_WIDTH :
        -this.CAROUSEL_SCROLL_WIDTH)),
      behavior: 'smooth'
    });
  }

  updateArrows() {
    this.showLeftArrow = this.carouselElement.nativeElement.scrollLeft;
    this.showRightArrow = (this.carouselElement.nativeElement.scrollLeft + this.carouselElement.nativeElement.clientWidth)
      < this.carouselElement.nativeElement.scrollWidth;
  }

  private read(labels: string) {

    // Reset error
    this.errorReading = '';

    // Read blog posts from blogger
    this.blogDataService.read(labels)
      .subscribe(
        (value) => {

            // Set posts
            this.posts = this.blogSharingService.processPosts((value.items || []), this.mode);

            // Set telemetry after the next digest (so posts are rendered in DOM)
            setTimeout(() => this.setTelemetry(), 0);
        },
        (error) => this.errorReading = error
      );
  }

  private setTelemetry() {

    // Set the telemetry for each post
    this.posts.forEach(post => {
      this.telemetryService.inject('CLICK', `blogger-${post.id}`, '/', true);
    });
    this.telemetryService.inject('CLICK', 'blog-posts-scroll-left', '/', true);
    this.telemetryService.inject('CLICK', 'blog-posts-scroll-right', '/', true);
  }

}
