<h1 class="cwk-orange text-center">{{ title }}</h1>

<!-- Loading -->
<div *ngIf="!posts && !errorReading" class="cwk-loading" [@slideInOut]>
  <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Loading...
</div>

<div [hidden]="!posts?.length || errorReading" class="blog-carousel-container">
  <div #carousel class="parent-overflow" (scroll)="updateArrows()">

    <!-- Scroll left button -->
    <div class="scroll-btn left" id="blog-posts-scroll-left" [ngClass]="{'disabled' : !showLeftArrow}" (click)="scrollCarousel('LEFT')">
      <fa-icon [icon]="faChevronLeft" size="2x"></fa-icon>
    </div>

    <!-- Posts -->
    <div class="d-flex">
      <div *ngFor="let post of posts; last as isLast">
        <div id="{{ 'blogger-' + post.id }}" [attr.tm-destination]="baseURL + '/' + post.path" class="tile-fixed-width" [ngClass]="{'tile-spacer' : !isLast}">
          <a target="_blank" [routerLink]="[baseURL + '/' + post.path]" class="tile-container cwk-link d-flex flex-column h-100">
            <img class="img-fluid thumbnail border" [src]="post.thumbnail" alt="{{ post.Title }}" onerror="this.onerror = null; this.src='https://d3t4xfu733v2tb.cloudfront.net/web/no-image.png'" loading="lazy">
            <div class="d-flex flex-column justify-content-between h-100">
              <div class="blog-title">{{ post.title || 'Untitled' }}</div>
              <div class="published border-bottom">Published on {{ post.published | date }} by {{ post.author.displayName }}</div>
              <div class="limit-lines">{{ post.content }}</div>
            </div>
            <div class="mt-2 cwk-link cwk-blue-link text-center">Read more...</div>
          </a>
        </div>
      </div>
    </div>

    <!-- Scroll right button -->
    <div class="scroll-btn right" id="blog-posts-scroll-right" [ngClass]="{'disabled' : !showRightArrow}" (click)="scrollCarousel('RIGHT')">
      <fa-icon [icon]="faChevronRight" size="2x"></fa-icon>
    </div>
  </div>
</div>

<!-- No posts -->
<div *ngIf="posts && !posts.length" class="p-3 mt-3 cwk-extralightgrey-bg">
  <h3 class="my-0">Could not find any posts!</h3>
</div>

<!-- Error reading -->
<cwk-error-alert *ngIf="errorReading" [error]="errorReading"></cwk-error-alert>
