import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';

import { environment } from 'environments/environment';
import { AuthService } from '../../services/auth.service';
import { GoogleSignInService } from '../../services/google-sign-in.service';
import { WINDOW } from '@shared/services/window.service';

@Component({
  selector: 'cwk-sign-in-out',
  templateUrl: './sign-in-out.component.html',
  styleUrls: ['./sign-in-out.component.scss'],
  standalone: false
})
export class SignInOutComponent implements OnInit {

  environment = environment;
  isBrowser = !!this.window;
  isExclamationMarkShown = true;

  constructor(
    private authService: AuthService,
    private googleSignInService: GoogleSignInService,
    @Inject(LOCALE_ID) private locale: string,
    @Inject(WINDOW) private window: Window | null
  ) {}

  ngOnInit() {

    // Show exclamation mark after name for english locale
    this.isExclamationMarkShown = !this.locale || (this.locale.substring(0, 2) === 'en');

    // Initialize Google Sign-in service
    this.googleSignInService.initialize();
  }

  // Unauthenticated user wants to create new account
  createAccount() {
    if (this.window) {
      this.window.location.href = environment.urlRoot + '/create-account' + this.window.location.search;
    }
  }

  // Get name of the currently signed in user
  getName() {
    const sessionInfo = this.authService.getSessionInfo();
    return !sessionInfo ? '' :
      (sessionInfo.FirstName + (sessionInfo.LastName ? (' ' + sessionInfo.LastName) : ''));
  }

  isSignedIn() {
    return this.authService.isAuthenticated();
  }

  signIn() {

    // For non-CwK projects, we do sign-in page redirect
    if (this.window && (this.environment.project !== 'cwk')) {
      this.window.location.href = '/sign-in';
      return;
    }

    // Sign in
    this.authService.signIn('signin')
      .then(() => {
        const sessionInfo = this.authService.getSessionInfo();
        if (this.window && sessionInfo.Roles && sessionInfo.Roles.length && (sessionInfo.Roles[0] === 'STUDENT')) {
          this.window.location.href = '/' + environment.urlStudentProfilePath;
        }
      });
  }

  signOut() {

    // Sign out - navigate to sign-out URL
    this.authService.signOut()
      .subscribe(() => {
        if (this.window) {
          this.window.location.href = environment.urlSignOut/* + this.window.location.search*/;
        }
      });
  }
}
