<!-- Loading -->
<div class="cwk-loading" *ngIf="isReading">
  <fa-icon class="me-2" [icon]="faSpinner" animation="spin"></fa-icon>Loading...
</div>

<!-- Testimonials -->
<div *ngIf="!isReading && testimonials.length" class="carousel-container">
  <div #carousel class="parent-overflow" (scroll)="updateArrows(carousel)" (mousedown)="mouseDown($event, carousel)" (mouseup)="mouseOff()"
  (mouseleave)="mouseOff()" (mousemove)="mouseMoved($event, carousel)">

    <!-- Scroll left button -->
    <div *ngIf="showLeftArrow" class="scroll-btn left" id="testimonials-scroll-left" (click)="scrollCarousel('LEFT', carousel)">
      <fa-icon [icon]="faChevronLeft" size="2x"></fa-icon>
    </div>

    <!-- Testimonials -->
    <div class="carousel-item-container" *ngFor="let testimonial of testimonials; index as i">
      <div class="carousel-item p-3">
        <home-customer-testimonial-item class="h-100" [limitLines]="5" [author]="testimonial.FirstName + ' ' + (testimonial.LastName | slice:0:1) + '.'" [date]="testimonial.CreatedOn" [review]="testimonial.Detail" [stars]="testimonial.Feedback"></home-customer-testimonial-item>
      </div>
    </div>

    <!-- Scroll right button -->
    <div *ngIf="showRightArrow" class="scroll-btn right" id="testimonials-scroll-right" (click)="scrollCarousel('RIGHT', carousel)">
      <fa-icon [icon]="faChevronRight" size="2x"></fa-icon>
    </div>
  </div>
</div>

<!-- Error -->
<cwk-error-alert *ngIf="errorReading" [error]="errorReading"></cwk-error-alert>
