<div class="region-switch">

  <!-- Spinner while checking regions -->
  <span *ngIf="isInitializing"><fa-icon [icon]="faSpinner" animation="spin"></fa-icon>&nbsp;Checking available regions...</span>

  <!-- Error status -->
  <span *ngIf="errorReadRegions">{{errorReadRegions}}</span>

  <!-- Selected region(s) -->
  <div [ngClass]="{'cwk-pointer':(regions.length > 1)}" (click)="(regions.length > 1) && openMenu()" *ngIf="!isInitializing && !errorReadRegions">
    <span *ngIf="selectedAll && (selectedCount > 2)">All regions</span>
    <span *ngIf="!selectedAll && selectedAllActive && (selectedCount > 2)">All active regions</span>
    <span *ngIf="!selectedAll && !selectedAllActive && (selectedCount > 2)">{{regionsSelected[0].Name}} and {{selectedCount - 1}} other regions</span>
    <span *ngIf="selectedCount === 2">{{regionsSelected[0].Name}} and {{regionsSelected[1].Name}}</span>
    <span *ngIf="selectedCount === 1">{{regionsSelected[0].Name}}</span>
    <span *ngIf="!selectedCount">No regions</span>
  </div>

  <!-- Regions menu -->
  <div class="menu" *ngIf="isMenuShown">

    <!-- Buttons select/deselect all -->
    <div class="fix-row">
      <div class="col-12">
        <b class="me-1 {{checkNoneSelected(true) ? 'disabled' : 'cwk-pointer'}}" (click)="setAllRegionsSelected(true, false)">CLEAR ALL</b>
        <b class="{{checkAllSelected(true) ? 'disabled' : 'cwk-pointer'}}" (click)="setAllRegionsSelected(true, true)">| SELECT ALL</b>
      </div>
    </div>

    <!-- List of regions -->
    <hr class="line"/>
    <div *ngFor="let region of regionsActive">
      <div *ngIf="region.ShowOrganizationName" class="region-group">{{region.OrganizationName}}</div>
      <div class="cwk-pointer" (click)="switchSelection(region)">
        <fa-icon [icon]="region.IsSelected ? faCheckSquare : faSquare"></fa-icon>
        {{region.Name}}
      </div>
    </div>

    <!-- List of inactive regions -->
    <div *ngIf="isAdmin && regionsInactive.length" class="inactive-regions">
      <div class="region-group">Inactive Regions</div>
      <div class="fix-row mt-1">
        <div class="col-12">
          <b class="me-1 {{checkNoneSelected(false) ? 'disabled' : 'cwk-pointer'}}" (click)="setAllRegionsSelected(false, false)">CLEAR ALL</b>
          <b class="{{checkAllSelected(false) ? 'disabled' : 'cwk-pointer'}}" (click)="setAllRegionsSelected(false, true)">| SELECT ALL</b>
        </div>
      </div>
      <div *ngFor="let region of regionsInactive">
        <div class="cwk-pointer" (click)="switchSelection(region)">
          <fa-icon [icon]="region.IsSelected ? faCheckSquare : faSquare"></fa-icon>
          {{region.Name}}
        </div>
      </div>
    </div>

    <!-- Buttons to cancel/save -->
    <hr class="line"/>
    <div class="button-row">
      <b class="cwk-pointer me-1" (click)="cancel()">CANCEL |</b>
      <b [class]="checkAnySelected() ? 'cwk-pointer' : 'disabled'" (click)="saveSelection()">
        <span *ngIf="isSaving; else save"><fa-icon [icon]="faSpinner" animation="spin"></fa-icon>&nbsp;Saving...</span>
        <ng-template #save>SAVE</ng-template>
      </b>
    </div>
  </div>
</div>

