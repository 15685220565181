import { Component, OnInit } from '@angular/core';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { AuthService } from '../../modules/auth/services/auth.service';
import { RegionSwitchHttpService } from './region-switch-http.service';

@Component({
  selector: 'cwk-region-switch',
  templateUrl: './region-switch.component.html',
  styleUrls: ['./region-switch.component.scss'],
  providers: [RegionSwitchHttpService],
  standalone: false
})
export class RegionSwitchComponent implements OnInit {

  // Icon
  faCheckSquare = faCheckSquare;
  faSpinner = faSpinner;
  faSquare = faSquare;

  errorReadRegions = '';
  errorUpdateSelected = '';
  isAdmin = false;
  isInitializing = false;
  isMenuShown = false;
  isSaving = false;
  regions = [];
  regionsActive = [];
  regionsInactive = [];
  regionsSelected = [];
  selectedAll = false;
  selectedAllActive = false;
  selectedCount = 0;

  private regionsOriginal = [];

  constructor(
    private authService: AuthService,
    private regionSwitchHttpService: RegionSwitchHttpService
  ) { }

  ngOnInit() {

    // Set permissions
    this.isAdmin = this.authService.isAuthorized(['ADMIN', 'FINANCE']);

    // Read regions
    this.readRegions();
  }

  cancel() {

    // Close menu
    this.isMenuShown = false;

    // Restore regions
    this.regions = this.regionsOriginal;
  }

  checkAllSelected(isActive: boolean) {
    for (const region of this.regions) {
      if ((region.IsActive === isActive) && !region.IsSelected) {
        return false;
      }
    }
    return true;
  }

  checkAnySelected() {
    for (const region of this.regions) {
      if (region.IsSelected) {
        return true;
      }
    }
    return false;
  }

  checkNoneSelected(isActive: boolean) {
    for (const region of this.regions) {
      if ((region.IsActive === isActive) && region.IsSelected) {
        return false;
      }
    }
    return true;
  }

  openMenu() {

    if (!this.isMenuShown) {

      // Store original values to enable cancelling of changes
      this.regionsOriginal = this.regions.map(a => Object.assign({}, a));

      // Show menu
      this.isMenuShown = true;
    } else {

      // Cancel menu
      this.cancel();
    }

  }

  // Save current selection
  saveSelection() {

    // Check if any region is selected
    if (!this.checkAnySelected()) {
      return;
    }

    // Set status
    this.isSaving = true;

    // Reset error status
    this.errorUpdateSelected = '';

    // Prepare regions payload
    const _regions = this.regions.map(region => {
      return {
        LocalityId: region.LocalityId,
        IsSelected: region.IsSelected
      };
    });

    // Save regions
    this.regionSwitchHttpService.updateSelected(_regions)
      .subscribe(
        (value) => {
          this.isMenuShown = false;
          this.getSelectedInfo();
        },
        (error) => {
          this.errorUpdateSelected = 'ERROR';
          console.error('Error while saving region switch selection: ' + JSON.stringify(error));
        },
        () => this.isSaving = false
      );

  }

  // Select / deselect all regions
  setAllRegionsSelected(isActive, isSelected) {
    this.regions.forEach(region => {
      if (region.IsActive === isActive) {
        region.IsSelected = isSelected;
      }
    });
  }

  sortByOrganization() {

    // First sort by organization and then by region name
    this.regions.sort((a, b) => {
      return ((a.OrganizationId - b.OrganizationId) || (a.Name > b.Name ? 1 : -1));
    });

    // Set flag to determine if the organization name should be shown
    // Only show organization name before its first region. Do not show CwK Organization (it is always first)
    for (let i = 1; i < this.regions.length; i++) {
      this.regions[i].ShowOrganizationName = this.regions[i - 1].OrganizationId !== this.regions[i].OrganizationId;
    }
  }

  // Switch region's selection status
  switchSelection(region) {
    region.IsSelected = !region.IsSelected;
  }

  private getSelectedInfo() {

    // Get selected regions
    this.regionsSelected = this.regions.filter(region => region.IsSelected);

    // Set auxiliary numbers
    this.selectedCount = this.regionsSelected.length;
    this.selectedAll = (this.selectedCount === this.regions.length);

    // Check whether all active are selected
    if (this.isAdmin) {
      this.selectedAllActive = true;
      this.regions.forEach(region => {
        if (region.IsActive !== region.IsSelected) {
          this.selectedAllActive = false;
        }
      });
    }
  }

  private readRegions() {

    // Show spinner
    this.isInitializing = true;

    // Reset error status
    this.errorReadRegions = '';

    // Read regions
    this.regionSwitchHttpService.readRegions()
      .subscribe(
        (value) => {
          this.regions = value;
          this.sortByOrganization();
          this.regionsActive = this.regions.filter(region => region.IsActive);
          this.regionsInactive = this.regions.filter(region => !region.IsActive);
          this.getSelectedInfo();
        },
        (error) => this.errorReadRegions = error,
        () => this.isInitializing = false
      );
  }
}
