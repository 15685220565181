import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Configuration } from '../../interfaces/configuration';
import { ConfigurationService } from '@shared/services/configuration.service';
import { GeolocationService } from '@shared/services/geolocation.service';
import { SpecialPageSharingService } from '../../../shared/services/special-page-sharing.service';

// Icons
import { faChevronLeft, faChevronRight, faStar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'home-program-tiles',
  templateUrl: './program-tiles.component.html',
  styleUrls: ['./program-tiles.component.scss', '../customer-testimonials/customer-testimonials.component.scss'],
  standalone: false
})
export class ProgramTilesComponent implements OnInit {

  @Input() configuration: Configuration;
  @ViewChild('carousel', {static: true}) carouselElement: ElementRef;

  geolocation = null;
  showLeftArrow = false;
  showRightArrow = true;

  // Camp tile configuration
  keyCampTitle = 'HOMEPAGE-PROGRAM-TILE-CAMP-TITLE';
  campTitle: string = 'CAMPS';

  // Early-bird
  keyFileEarlyBird = 'EARLY-BIRD-FILE-ONLINE-CAMPS';
  keyShowEarlyBird = 'EARLY-BIRD-HIDE-TIMESTAMP-ONLINE-CAMPS';
  fileEarlyBird: string = null;
  showEarlyBird = false;

  // Icons
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faStar = faStar;

  readonly CAROUSEL_SCROLL_WIDTH = 380;

  constructor(
    private configurationService: ConfigurationService,
    private geolocationService: GeolocationService,
    private router: Router,
    private specialPageSharingService: SpecialPageSharingService
  ) { }

  ngOnInit() {

    // Get current geolocation info
    this.geolocationService.getObservable()
      .subscribe((value) => this.geolocation = value);

    // Get camp tile configuration
    this.configurationService.getItem(this.keyCampTitle)
      .subscribe((value) => this.campTitle = value.Value);

    // Get early-bird configuration
    this.configurationService.getItem(this.keyFileEarlyBird)
      .subscribe((value) => this.fileEarlyBird = value.Value);

    this.configurationService.getItem(this.keyShowEarlyBird)
      .subscribe((value) => this.showEarlyBird = value && (new Date(value.Value) > new Date()));
  }

  goURL(url: any[]) {
    event.stopPropagation(); // Prevent outer link from triggering
    this.router.navigate(url);
  }

  scrollCarousel(direction: string) {
    this.carouselElement.nativeElement.scrollTo({
      left: (this.carouselElement.nativeElement.scrollLeft + (direction === 'RIGHT' ? this.CAROUSEL_SCROLL_WIDTH :
        -this.CAROUSEL_SCROLL_WIDTH)),
      behavior: 'smooth'
    });
  }

  staticPrice4ClassType4Currency(classType: string): [number, number] {
    const currency = this.geolocation ? this.geolocation.currency : 'USD';
    return this.specialPageSharingService.staticPrice4ClassType4Currency(classType, currency, null);
  }

  updateArrows() {
    this.showLeftArrow = this.carouselElement.nativeElement.scrollLeft;
    this.showRightArrow = (this.carouselElement.nativeElement.scrollLeft + this.carouselElement.nativeElement.clientWidth)
      < this.carouselElement.nativeElement.scrollWidth;
  }
}
