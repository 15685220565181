import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-awards-and-recognition',
  templateUrl: './awards-and-recognition.component.html',
  styleUrls: ['./awards-and-recognition.component.scss'],
  standalone: false
})
export class AwardsAndRecognitionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
