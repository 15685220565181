<div class="mt-4 mb-3">
  It is essential that every one of our students has an excellent experience in our classes. The quality of our classes and the satisfaction of our students is always our top consideration.
</div>
<div class="mb-3 cwk-orange text-center">
  <h4 class="my-0">TOGETHER, we make that happen!</h4>
</div>
<div class="mb-3">We take many steps to make our classes great for students but know that not everything goes 100%. Should your experience in our classes be anything less than fabulous, please reach out to us right away! We will work with you to adapt the class to your student’s needs or to find them a different class to provide the best experience.</div>
<div class="d-flex justify-content-center mb-5">
  <a class="cwk-button fs-5 px-5" [routerLink]="['/contact']" [queryParams]="{ ft: 'Commitment to quality - Reach out to us', s: 'Commitment to Quality' }" queryParamsHandling="merge">Reach out to us</a>
</div>
<div class="fix-row">

  <!-- Seal image -->
  <div class="d-none d-lg-block col-md-12 col-lg-4 col-xl-3 mb-3 mb-lg-0">
    <div class="d-flex flex-column justify-content-center align-items-center h-100">
      <div class="seal-img">
        <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/seal-2.png" alt="Coding with Kids quality guaranteed" loading="lazy"/>
      </div>
    </div>
  </div>

  <div class="col-md-12 col-lg-8 col-xl-9">
    Some of the things we do to make our classes the best:
    <ul class='mt-2'>
      <li>
        <b>INSTRUCTORS:</b> Great teachers make all the difference. That’s why we invest a lot of time into carefully selecting and training our instructors. We also provide continued coaching based on class observations and your feedback.
      </li>
      <li>
        <b>PROGRAMS:</b> Coding with Kids’ programs have been designed by Computer Science experts. We also continuously improve and expand our curriculum based on feedback from our instructors and students.
      </li>
      <li>
        <b>CONTINUOUS IMPROVEMENTS:</b> We are always looking to improve all aspects of your experience at Coding with Kids. Whether it is raising the bar for our classes or automating parts of our internal processes to make sure we never miss a beat, we want to give you the best experience in coding.
      </li>
      <!--li>
        <a class="cwk-link cwk-blue-link" [routerLink]="['/contact']" queryParamsHandling="preserve"><b>REACH OUT TO US!</b></a> Should your experience in our classes be any less than fabulous, please reach out to us right away! We will work with you to adapt to your student and find them the class that best fits their needs. We want every child in our classes to have the best experience ever.
      </li-->
    </ul>
  </div>

  <!-- Seal image -->
  <div class="d-block d-lg-none col-12 mb-0 mt-3">
    <div class="d-flex flex-column justify-content-center align-items-center h-100">
      <div class="seal-img">
        <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/homepage/seal-2.png" alt="Coding with Kids quality guaranteed" loading="lazy"/>
      </div>
    </div>
  </div>
</div>
