import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router, ResolveEnd } from '@angular/router';

import { AuthService } from '../../modules/auth/services/auth.service';
import { OrganizationDataService } from '../../services/organization-data.service';
import { OrganizationService } from '../../services/organization.service';
import { RegistrationCampDataService } from '../../services/registration-camp-data.service';
import { RegistrationCampMenuInfo } from '../../interfaces/registration-camp-menu-info';
import { StaffDataService } from '../../services/staff-data.service';
import { TelemetryService } from '@shared/services/telemetry.service';
import { WINDOW } from '@shared/services/window.service';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';

// Icons
import { faBars, faExclamationTriangle, faMapMarkerAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cwk-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss', '../../shared/styles/header-menu.scss'],
  providers: [OrganizationDataService, StaffDataService],
  standalone: false
})
export class HeaderMenuComponent implements OnInit {

  @Input() activeTab: string;

  readonly CWK_ORGANIZATION_ID = this.organizationService.CWK_ORGANIZATION_ID;
  readonly CWK_PARTNER = 'cwkcwk';

  campMenuInfo: RegistrationCampMenuInfo[] | null = null;
  envPrefix = environment.urlAppHomePrefix ? ('/' + environment.urlAppHomePrefix) : '';
  isMobileMenuOpened = false;
  organization: any = null;
  upcomingLesson: any = null;
  urlRoot = environment.urlRoot;

  // Icons
  faBars = faBars;
  faExclamationTriangle = faExclamationTriangle;
  faMapMarkerAlt = faMapMarkerAlt;
  faSpinner = faSpinner;

  // Progress
  errorReadingOrganization = '';
  errorReadingUpcomingLesson = '';
  isReadingOrganization = false;
  isReadingUpcomingLesson = true;

  constructor(
    private authService: AuthService,
    private organizationDataService: OrganizationDataService,
    private organizationService: OrganizationService,
    private registrationCampDataService: RegistrationCampDataService,
    private router: Router,
    private staffDataService: StaffDataService,
    private telemetryService: TelemetryService,
    @Inject(WINDOW) private window: Window | null
  ) {

    // Set telemetry once the router has resolved the final route
    this.router.events.subscribe((routerData) => {
      if (routerData instanceof ResolveEnd) {
        this.setTelemetry(routerData.url);
      }
    });
  }

  ngOnInit(): void {

    // If there is no organization set, default to CwK
    if (!this.organizationService.getOrganization()) {
      this.organizationService.setOrganization(this.CWK_ORGANIZATION_ID);
    }

    // Watch organization for changes
    this.organizationService.getOrganizationChanged()
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(organizationId => {

        // Read the organization. Default to CwK if organization was not found
        this.readOrganization(organizationId);
      });

    // Read camp seasons
    this.readCampMenuInfo();
  }

  goHome() {
    if (this.window) {
      const envPrefix = environment.urlAppHomePrefix ? (environment.urlAppHomePrefix + '/') : '';
      this.window.location.href = (this.organization.Code === 'cwk' ? '/' : ('/' + envPrefix + this.organization.Code))/* + this.window.location.search*/;
    }
  }

  goUrl(url: string) {
    if (this.window) {
      this.window.location.href = environment.urlRoot + url/* + this.window.location.search*/;
    }
  }

  isAdminPage(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'LEVEL', 'MANAGER', 'ORGADMIN', 'PAYROLL', 'REGMANAGER', 'REFUND']);
  }

  isInstructor(): boolean {
    return this.authService.isAuthorized(['TEACHER']);
  }

  isInternal(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'CURRICULUM', 'CUSTOMERFEEDBACK', 'EMAILTEMPLATES', 'EQUIPMENT', 'FINANCE', 'GRADMANAGER', 'GRADPROCTOR', 'IMPORT', 'INTERNATIONALSUP', 'INTERVIEWER', 'LEVEL', 'MANAGER', 'OBSERVER', 'ORGADMIN', 'PAYROLL', 'PEOPLEMANAGER', 'PURCHASE', 'RECRUITER', 'REFUND', 'REGMANAGER', 'SESSIONIMPORT', 'STAFF', 'TIMESHEET', 'TEACHER', 'TRAINING']);
  }

  isLicensed(feature: string): boolean {
    return this.authService.isLicensed(feature);
  }

  isManager(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'MANAGER', 'ORGADMIN', 'REGMANAGER']);
  }

  isPayroll(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'ORGADMIN', 'PAYROLL']);
  }

  isRecruiting(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'INTERVIEWER', 'ORGADMIN', 'RECRUITER', 'REGMANAGER', 'STAFF']);
  }

  isSignedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  isStaffTraining(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'ORGADMIN', 'REGMANAGER', 'STAFF', 'TRAINING']);
  }

  isTimesheet(): boolean {
    return this.authService.isAuthorized(['ADMIN', 'ORGADMIN', 'PAYROLL', 'TIMESHEET']);
  }

  isTimeTracking(): boolean {
    return !this.authService.isAuthorized(['ADMIN', 'ORGADMIN', 'PAYROLL', 'TIMESHEET']);
  }

  signOut() {

    // Sign out - navigate to sign-out URL
    this.authService.signOut()
      .subscribe(() => {
        if (this.window) {
          this.window.location.href = environment.urlSignOut/* + this.window.location.search*/;
        }
      });
  }

  switchMobileMenu() {
    this.isMobileMenuOpened = !this.isMobileMenuOpened;
  }

  private processCampMenuInfo(value: RegistrationCampMenuInfo[]) {

    this.campMenuInfo = value;
    this.campMenuInfo.forEach(info => {
      const parts = info.Name.split(' ', 2);
      info.DisplayName = parts[1] + ' Camps ' + (info.IsOnline ? 'Live Online' : 'In-person');
      info.TelemetryDestination = info.IsOnline ? '/online-camps' : '/in-person-camps';
      info.TelemetryId = info.IsOnline ? 'coders-pathways-schedule-camps' : 'coders-pathways-schedule-in-person-camps';
      info.URL = (info.IsOnline ? '/online-camps' : '/in-person-camps') + '?season=' + info.Code;
    });
  }

  private readCampMenuInfo(): void {

    // Currently only CwK partner is supported
    const partner = this.CWK_PARTNER;

    // Read menu info
    this.registrationCampDataService.readMenuInfo(partner)
      .subscribe(
        (value) => {
          this.processCampMenuInfo(value);
        }
      );
  }

  private readOrganization(organizationId: number): void {
    this.errorReadingOrganization = '';
    this.organization = null;
    this.isReadingOrganization = true;

    // Read organization
    this.organizationDataService.read(organizationId)
      .subscribe(
        (value) => {
          this.organization = value;

          // Set the home link for the organization
          this.organization.HomeLink = this.organization.Code === 'cwk' ?
            '/' : (this.envPrefix + '/region/' + this.organization.URLIdentifier);

          this.isReadingOrganization = false;
        },
        (error) => {
          this.errorReadingOrganization = error;
          this.isReadingOrganization = false;
        }
      );
  }

  readUpcomingLesson(): void {

    if (!this.isInstructor()) {
      return;
    }

    this.errorReadingUpcomingLesson = '';
    this.isReadingUpcomingLesson = true;
    this.upcomingLesson = null;

    // Read upcoming lesson
    this.staffDataService.readUpcomingLesson(this.authService.getUserId())
      .subscribe(
        (value) => {
          this.upcomingLesson = value;
          this.isReadingUpcomingLesson = false;
        },
        (error) => {
          this.errorReadingUpcomingLesson = error;
          this.isReadingUpcomingLesson = false;
        }
      );
  }

  private setTelemetry(sourcePath: string): void {
    this.telemetryService.inject('CLICK', 'home-menu-item', sourcePath, true);
    this.telemetryService.inject('CLICK', 'coders-ladder-schedule-classes', sourcePath, true);
    this.telemetryService.inject('CLICK', 'coders-pathways-schedule-classes', sourcePath, true);
    this.telemetryService.inject('CLICK', 'coders-pathways-schedule-camps', sourcePath, true);
    this.telemetryService.inject('CLICK', 'curriculum-coders-ladder', sourcePath, true);
    this.telemetryService.inject('CLICK', 'curriculum-coders-pathways', sourcePath, true);
    this.telemetryService.inject('CLICK', 'private-classes-schedule-classes', sourcePath, true);
    // this.telemetryService.inject('CLICK', 'adult-classes-schedule-classes', sourcePath, true);
    this.telemetryService.inject('CLICK', 'about-us-history', sourcePath, true);
    this.telemetryService.inject('CLICK', 'about-us-management', sourcePath, true);
    this.telemetryService.inject('CLICK', 'about-us-jobs', sourcePath, true);
    this.telemetryService.inject('CLICK', 'about-us-contact', sourcePath, true);
    this.telemetryService.inject('CLICK', 'about-us-blog', sourcePath, true);
    this.telemetryService.inject('CLICK', 'about-us-our-students', sourcePath, true);
    this.telemetryService.inject('CLICK', 'about-us-faqs', sourcePath, true);
    // this.telemetryService.inject('CLICK', 'after-school-enrichment', sourcePath, true);
    this.telemetryService.inject('CLICK', 'coding-programs-for-schools', sourcePath, true);
  }
}
