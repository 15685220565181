<div class="program-carousel-container">
  <div #carousel class="d-flex justify-content-center justify-content-lg-start parent-overflow" (scroll)="updateArrows()">

    <!-- Scroll left button -->
    <div class="d-none d-lg-flex scroll-btn left" [ngClass]="{'disabled' : !showLeftArrow}" (click)="scrollCarousel('LEFT')">
      <fa-icon [icon]="faChevronLeft" size="2x"></fa-icon>
    </div>

    <!-- Program tiles -->
    <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between">
      <div class="order-3 order-lg-1 tile-fixed-width">
        <ng-container *ngTemplateOutlet="programTile; context: { tile: configuration.programTile1 }"></ng-container>
      </div>
      <div class="mb-5 order-2 tile-spacer"></div>
      <div class="order-1 order-lg-3 tile-fixed-width">
        <ng-container *ngTemplateOutlet="programTile; context: { tile: configuration.programTile2 }"></ng-container>
      </div>
      <div class="mb-5 order-4 tile-spacer"></div>
      <div class="order-5 tile-fixed-width">
        <ng-container *ngTemplateOutlet="programTile; context: { tile: configuration.programTile3 }"></ng-container>
      </div>
      <div class="mb-5 order-last tile-spacer"></div>
      <div class="order-last tile-fixed-width">
        <ng-container *ngTemplateOutlet="programTile; context: { tile: configuration.programTile4 }"></ng-container>
      </div>
    </div>

    <!-- Scroll right button -->
    <div class="d-none d-lg-flex scroll-btn right" [ngClass]="{'disabled' : !showRightArrow}" (click)="scrollCarousel('RIGHT')">
      <fa-icon [icon]="faChevronRight" size="2x"></fa-icon>
    </div>
  </div>
</div>

<ng-template #programTile let-tile="tile">
  <ng-container *ngIf="tile === 'CAMP'">
    <ng-container *ngTemplateOutlet="campTile"></ng-container>
  </ng-container>
  <ng-container *ngIf="tile === 'CODERS-LADDER'">
    <ng-container *ngTemplateOutlet="codersLadderTile"></ng-container>
  </ng-container>
  <ng-container *ngIf="tile === 'ENRICHMENT'">
    <ng-container *ngTemplateOutlet="enrichmentTile"></ng-container>
  </ng-container>
  <ng-container *ngIf="tile === 'PRIVATE'">
    <ng-container *ngTemplateOutlet="privateTile"></ng-container>
  </ng-container>
</ng-template>

<!-- Camps -->
<ng-template #campTile>
  <div id="program-tile-camp" tm-destination="/online-camps">
    <div class="flagship-height d-none d-lg-block"></div>
    <a class="tile-container cwk-link" [routerLink]="['/online-camps']" queryParamsHandling="preserve">
      <div class="solid-bar cwk-darkyellow-bg"></div>
      <picture>
        <source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-camps.webp" type="image/webp" />
        <img alt="Coding with Kids Online Camps Banner" class="img-fluid" loading="lazy" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-camps.jpg" width="1300" height="650" />
        <!--source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/winter-camps.webp" type="image/webp" />
        <img alt="Coding with Kids Online Camps Banner" class="img-fluid" loading="lazy" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/winter-camps.jpg" width="1300" height="650" /-->
      </picture>
      <div class="program-title cwk-darkyellow-bg">

        <!-- Early bird & new camps image -->
        <div class="position-relative">
          <!--img class="img-fluid new-camps-img" src="https://d3t4xfu733v2tb.cloudfront.net/logo/new-camps-icon.png" alt="New camps image" loading="lazy"/-->

          <img *ngIf="showEarlyBird" class="img-fluid position-absolute early-bird-img" src="https://d3t4xfu733v2tb.cloudfront.net/logo/{{ fileEarlyBird }}" alt="Early bird image" loading="lazy" width="500" height="315" />
        </div>

        <div class="top">CODER'S PATHWAYS<span class="cwk-registration-icon">&reg;</span></div>
        <div class="bottom">{{ campTitle }}</div>
      </div>
      <div class="content">
        <div>Offered during school breaks</div>
        <div>Ages 5-18</div>
        <div class="mx-auto mini-orange-bar"></div>
        <div><b>Live online</b></div>
        <!--div>Mon-Fri, up to 3hrs/day</div-->
        <div>Up to 3 hrs/day</div>
        <div>{{ staticPrice4ClassType4Currency('CAMP HALF')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('CAMP HALF')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' for 5 days') : '&nbsp;' }}</div>
        <span class="cwk-link cwk-grey" (click)="goURL(['/in-person-camps'])">
          <div class="mt-2"><b>In-person</b></div>
          <div>{{ staticPrice4ClassType4Currency('CAMP HALF IN-PERSON')[0] ? ('Half-day camps starting at ' + (staticPrice4ClassType4Currency('CAMP HALF IN-PERSON')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0')) : '&nbsp;' }}</div>
          <div>{{ staticPrice4ClassType4Currency('CAMP FULL')[0] ? ('Full-day camps starting at ' + (staticPrice4ClassType4Currency('CAMP FULL')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0')) : '&nbsp;' }}</div>
        </span>
        <!--div><b>Live online / <a class="cwk-link cwk-grey" [routerLink]="['/in-person-camps']" queryParamsHandling="preserve">In-person</a></b></div>
        <div>1-3 hrs a day / Half or Full Day</div>
        <div>One week, Mon-Fri</div>
        <div class="mt-2">{{ staticPrice4ClassType4Currency('CAMP 2.5HR')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('CAMP 2.5HR')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' / 2.5hr a day') : '&nbsp;' }}</div>
        <div>{{ staticPrice4ClassType4Currency('CAMP HALF')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('CAMP HALF')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' / Half-day') : '&nbsp;' }}</div-->
      </div>
      <div class="solid-bar cwk-darkyellow-bg"></div>
    </a>
    <!--div class="review">
      <home-customer-testimonial-item author="Julianna B." review="In fact, my daughter said that this camp was way more than just fantastic. It was incredible! My daughter really enjoyed it and hopes to go back soon."></home-customer-testimonial-item>
    </div-->
  </div>
</ng-template>

<!-- Coder's Ladder -->
<ng-template #codersLadderTile>
  <div id="program-tile-coders-ladder" tm-destination="/coders-ladder-group-classes">
    <a class="tile-container cwk-link" [routerLink]="['/coders-ladder-group-classes']" queryParamsHandling="preserve">
      <div class="solid-bar cwk-orange-bg"></div>
      <div class="d-flex justify-content-center align-items-center">
        <fa-icon class="cwk-darkyellow" [icon]="faStar"></fa-icon>
        <div class="d-flex align-items-center flagship-program">FLAGSHIP ACADEMY</div>
        <fa-icon class="cwk-darkyellow" [icon]="faStar"></fa-icon>
      </div>
      <picture>
        <source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-coders-ladder-classes.webp" type="image/webp" />
        <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/online-coders-ladder-classes.jpg" alt="Coder's ladder image" loading="lazy" width="1110" height="555" />
      </picture>
      <div class="program-title cwk-orange-bg">
        <div class="top">CODER'S LADDER<span class="cwk-registration-icon">&reg;</span></div>
        <div class="bottom">GROUP CLASSES</div>
        <!--div class="top">Ongoing live online classes</div>
        <div class="top">Core curriculum</div-->
      </div>
      <div class="content">
        <div>Offered year-round</div>
        <div>Ages 5-18</div>
        <div class="mx-auto mini-orange-bar"></div>
        <div><b>Live online</b></div>
        <div>Ongoing classes</div>
        <div>Meets once a week, 1:15h</div>
        <div class="mt-2">{{ (staticPrice4ClassType4Currency('CENTER CLASS')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' for 4 weeks' }}</div>
        <!--div>{{ staticPrice4ClassType4Currency('CENTER CLASS 2X')[0] ? ('Twice a week: ' + (staticPrice4ClassType4Currency('CENTER CLASS 2X')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' / 8 weeks') : '&nbsp;' }}</div-->
        <div>8-week billing cycle</div>
        <div>Discounted annual subscription available</div>
      </div>
      <div class="solid-bar cwk-orange-bg"></div>
    </a>
    <!--div class="review">
      <home-customer-testimonial-item author="Lainie A." review="Thanks again for your great work with R. this semester. I know he learned a lot… thank you to all of you for putting the time in and working so patiently with our kids."></home-customer-testimonial-item>
    </div-->
  </div>
</ng-template>

<!-- Enrichment -->
<ng-template #enrichmentTile>
  <div id="program-tile-enrichment" tm-destination="/in-person-enrichment-classes">
    <div class="flagship-height d-none d-lg-block"></div>
    <a class="tile-container cwk-link" [routerLink]="['/in-person-enrichment-classes']" queryParamsHandling="preserve">
      <div class="solid-bar cwk-darkyellow-bg"></div>
      <picture>
        <source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/in-person-enrichment-classes.webp" type="image/webp" />
        <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/in-person-enrichment-classes.jpg" alt="Enrichment classes image" loading="lazy" width="1110" height="555" />
      </picture>
      <div class="program-title cwk-darkyellow-bg">
        <div class="top">CODER'S PATHWAYS<span class="cwk-registration-icon">&reg;</span></div>
        <div class="bottom">ENRICHMENT CLASSES</div>
        <!--div class="top">Short-term sessions during school year</div>
        <div class="top">Enrichment curriculum</div-->
      </div>
      <div class="content mb-3 mt-2">
        <div>Offered during school year</div>
        <div>Ages 5-18</div>
        <div class="mx-auto mini-orange-bar"></div>
        <div><b>In-person</b></div>
        <div>6 or 10-week sessions</div>
        <div>Once a week for 1 or 1.5 hrs</div>
        <div class="mt-2">{{ staticPrice4ClassType4Currency('AFTER-SCHOOL 6WK')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('AFTER-SCHOOL 6WK')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' for 6-week session') : '&nbsp;' }}</div>
        <div>{{ staticPrice4ClassType4Currency('AFTER-SCHOOL 10WK')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('AFTER-SCHOOL 10WK')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' for 10-week session') : '&nbsp;' }}</div>
      </div>
      <div class="solid-bar cwk-darkyellow-bg"></div>
    </a>
    <!--div class="review">
      <home-customer-testimonial-item author="Allison C." review="I've heard nothing, but raving reviews from those that have signed up. I know my daughter LOVES the class and I'm really impressed with your instructors patience and instruction with the kids. I actually just signed up both my daughter and son for the next session."></home-customer-testimonial-item>
    </div-->
  </div>
</ng-template>

<!-- Private classes -->
<ng-template #privateTile>
  <div id="program-tile-private" tm-destination="/private-classes">
    <!--div class="flagship-height-new-program"></div-->
    <div class="flagship-height d-none d-lg-block"></div>
    <a class="tile-container cwk-link" [routerLink]="['/private-classes']" queryParamsHandling="preserve">
      <!--div class="solid-bar-new-program cwk-orange-bg">
        * NEW PROGRAM *
      </div-->
      <div class="solid-bar cwk-darkyellow-bg"></div>
      <picture>
        <source srcset="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/private-classes.webp" type="image/webp" />
        <img class="img-fluid" src="https://d3t4xfu733v2tb.cloudfront.net/web/home/programs-banners/private-classes.jpg" alt="Private classes" loading="lazy" width="1110" height="555" />
      </picture>
      <div class="program-title cwk-darkyellow-bg">
        <div class="top">CODER'S LADDER<span class="cwk-registration-icon">&reg;</span> or PATHWAYS<span class="cwk-registration-icon">&reg;</span></div>
        <div class="bottom">PRIVATE CLASSES</div>
        <!--div class="top">Ongoing live online classes</div>
        <div class="top">Choose core or enrichment curriculum</div-->
      </div>
      <div class="content">
        <div>Offered year-round</div>
        <div>Ages 5-18</div>
        <div class="mx-auto mini-orange-bar"></div>
        <div><b>Live online</b></div>
        <div>Ongoing classes</div>
        <div>Meets once a week, 50 minutes</div>
        <div class="mt-2">{{ (staticPrice4ClassType4Currency('PRIVATE CLASS')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' for 4 weeks' }}</div>
        <!--div>{{ staticPrice4ClassType4Currency('PRIVATE CLASS')[0] ? ('Starting at ' + (staticPrice4ClassType4Currency('PRIVATE CLASS')[0] | currency: geolocation?.currency : 'symbol' : '1.0-0') + ' for a 8-week session') : '&nbsp;' }}</div-->
        <div>8-week billing cycle</div>
        <div>Discounted annual subscription available</div>
      </div>
      <div class="solid-bar cwk-darkyellow-bg"></div>
    </a>
    <!--div class="review">
      <home-customer-testimonial-item author="Marianne M." review="Fantastic experience! My daughter loved the projects and the teacher really got engaged with every lesson. It's such a relief to have such a great option during our days at home."></home-customer-testimonial-item>
    </div-->
  </div>
</ng-template>
