import { Component, Inject } from '@angular/core';

import { CookieConsentManagerService } from '@shared/components/cookie-consent-manager/services/cookie-consent-manager.service';
import { WINDOW } from '@shared/services/window.service';

import { environment } from 'environments/environment';

@Component({
  selector: 'cwk-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss'],
  standalone: false
})
export class PageFooterComponent {

  // Model
  now: Date = new Date();
  urlRoot = environment.urlRoot;

  constructor(
    private cookieConsentManagerService: CookieConsentManagerService,
    @Inject(WINDOW) private window: Window | null
  ) { }

  goUrl(url: string) {
    if (this.window) {
      this.window.location.href = environment.urlRoot + url/* + this.window.location.search*/;
    }
  }

  onClickManageCookies() {
    this.cookieConsentManagerService.manageCookies();
  }
}
