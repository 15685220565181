import { Component, OnInit } from '@angular/core';

import { FreshchatDataService } from '../../services/freshchat-data.service';

// Icons
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'cwk-freshchat-switch',
  templateUrl: './freshchat-switch.component.html',
  styleUrls: ['./freshchat-switch.component.scss'],
  providers: [FreshchatDataService],
  standalone: false
})
export class FreshchatSwitchComponent implements OnInit {

  isInitializing = true;
  isOnFreshChat = false;
  isSaving = false;

  faSpinner = faSpinner;

  constructor(
    private freshchatDataService: FreshchatDataService
  ) { }

  ngOnInit() {
    this.readStatus();
  }

  readStatus() {
    this.freshchatDataService.readFreshchatStatus()
      .subscribe(
        (value) => {

          // Initialized - hide the progress spinner
          this.isInitializing = false;

          // Store the status
          this.isOnFreshChat = !!value.IsOnFreshChat;
        },
        (error) => {

          // Initialized - hide the progress spinner
          this.isInitializing = false;

          // Show error
          console.error('Error reading Freshchat status: ' + error);
        }
      );
  }

  switchStatus() {
    this.freshchatDataService.updateFreshchatStatus(this.isOnFreshChat ? 0 : 1)
      .subscribe(
        (value) => {

          // Initialized - hide the progress spinner
          this.isSaving = false;

          // Switch the status
          this.isOnFreshChat = !this.isOnFreshChat;
        },
        (error) => {

          // Initialized - hide the progress spinner
          this.isInitializing = false;

          // Show error
          console.error('Error updating selection: ' + error);
        }
      );
  }
}
