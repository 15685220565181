import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@shared/modules/auth/auth.module';
import { HomepageModule } from './homepage/homepage.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CurrencySelectorComponent } from '@shared/components/currency-selector/currency-selector.component';
import { FreshchatComponent } from '@shared/components/freshchat/freshchat.component';
import { FreshchatSwitchComponent } from '@shared/components/freshchat-switch/freshchat-switch.component';
import { HeaderMenuComponent } from '@shared/components/header-menu/header-menu.component';
import { LocationsMenuComponent } from '@shared/components/locations-menu/locations-menu.component';
import { PageHeaderComponent } from '@shared/components/page-header/page-header.component';
import { PageFooterComponent } from '@shared/components/page-footer/page-footer.component';
import { RegionSwitchComponent } from '@shared/components/region-switch/region-switch.component';
import { SizeDetectorComponent } from '@shared/components/size-detector/size-detector.component';
import { TokenInterceptorService } from '@shared/services/token-interceptor.service';

@NgModule({
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent,
    CurrencySelectorComponent,
    FreshchatComponent,
    FreshchatSwitchComponent,
    HeaderMenuComponent,
    LocationsMenuComponent,
    PageHeaderComponent,
    PageFooterComponent,
    RegionSwitchComponent,
    SizeDetectorComponent
  ],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    HomepageModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    SharedModule
  ],
  providers: [
    provideClientHydration(),
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'en' },
    provideHttpClient(withFetch(), withInterceptorsFromDi())
  ]
})
export class AppModule { }
