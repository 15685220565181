import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { FreshchatDataService } from '../../services/freshchat-data.service';
import { WINDOW } from '@shared/services/window.service';

@Component({
  selector: 'cwk-freshchat',
  templateUrl: './freshchat.component.html',
  styleUrls: ['./freshchat.component.scss'],
  providers: [FreshchatDataService],
  standalone: false
})
export class FreshchatComponent {

  // Pages to show the FreshChat icon on
  showOnPages = {
    'ALL': [
        // '/account',
        '/classes-for-adults',
        '/coders-ladder',
        '/coders-pathways',
        '/in-person-camps',
        '/in-person-enrichment-classes',
        '/online-camps',
        '/online-coders-ladder-classes',
        '/online-enrichment-classes',
        '/private-classes',
        '/welcome/coders-ladder'
      ]
  };

  intervalId = null;
  isShown = false;

  constructor(
    private freshchatDataService: FreshchatDataService,
    private router: Router,
    @Inject(WINDOW) private window: Window | null
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        // Clear previous interval
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }

        // Initiate reading of freshchat availability status
        this.readStatus();
      }
    });
  }

  readStatus() {

    if (!this.window) {
      return;
    }

    this.freshchatDataService.readFreshchatStatusAll()
      .subscribe(
        (value) => {

          // Check whether the freshchat icon should be shown on the current page
          // Somebody needs to have their freshchat presence on and the page must belong to set of pages specified in the configuration
          const isShownOnPage = !!value.IsOnFreshChat &&
            (
              (this.showOnPages[value.Pages] && this.showOnPages[value.Pages].includes(location.pathname)) ||
              value.Pages.split(',').includes(location.pathname)
            );

          // If the status of the display changed, show or hide the icon
          if (isShownOnPage !== this.isShown) {

            if (isShownOnPage) {
              (this.window as any).fcWidget.init({
                token: 'f7466dfa-4bd8-4dd4-a901-5674c229be10',
                host: 'https://wchat.freshchat.com'
              });

              // Start watching the status
              this.intervalId = setInterval(() => { this.readStatus(); }, 60000);
            } else {
              (this.window as any).fcWidget.shutdown(); // or this.window.fcWidget.destroy();

              // Stop watching the status
              if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
              }
            }

            this.isShown = isShownOnPage;
          }
        }
      );
  }
}
